import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { newCampaignActions, screensActions } from '../../../../../store';
import { useSelector, useDispatch } from 'react-redux';

const Search = ({ applyFilters }) => {
    const { t } = useTranslation();
    const [searchType, setSearchType] = useState('name');
    const debounceRef = useRef(setTimeout(() => {}, 1000));
    const handleOnChangeColumnType = (type) => setSearchType(type);
    const dispatch = useDispatch();
    const filterBySearch = (toSearch) => {
        if (debounceRef.current) {
            clearInterval(debounceRef.current);
        }

        debounceRef.current = setTimeout(async () => {
            let maxCoincidencia = 0;
            const screensByCurrentFilters = applyFilters();
            if (toSearch.trim().length === 0) {
                dispatch(
                    screensActions.setScreensFilteredFromScreensWizard(
                        screensByCurrentFilters
                    )
                );
            } else {
                let screensBySearch = screensByCurrentFilters.map((screen) => {
                    const wordsToSearch = toSearch.toLowerCase().split(' ');
                    const publisherName = (screen && screen.publisher && screen.publisher.name) | ""
                    const wordsInScreen =
                        `${screen.id} ${publisherName} ${screen.name}`.toLowerCase();
                    const sameWords = wordsToSearch.filter((word) =>
                        wordsInScreen.includes(word)
                    );
                    if (sameWords.length > 0) {
                        if (sameWords.length > maxCoincidencia) {
                            maxCoincidencia = sameWords.length;
                        }
                        return { ...screen, wordsInScreen: sameWords.length };
                    } else {
                        return undefined;
                    }
                });

                screensBySearch = screensBySearch.filter((screen) => {
                    return screen && screen.wordsInScreen >= maxCoincidencia;
                });
                dispatch(
                    screensActions.setScreensFilteredFromScreensWizard(
                        screensBySearch
                    )
                );
            }
        }, 400);
    };

    return (
        <div className="md:w-6/6 md:mr-2">
            <div className="mt-1 relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <span className="text-gray-500 sm:text-sm">
                        <FontAwesomeIcon
                            icon={['far', 'search']}
                            fixedWidth
                            className="mr-1"
                        />
                    </span>
                </div>
                <input
                    onChange={(e) => filterBySearch(e.target.value)}
                    autoComplete="off"
                    type="search"
                    name="budget"
                    id="search-table"
                    className={`focus:ring-0 focus:outline-none block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md ml-1`}
                    placeholder={t(
                        'sections.campaigns.new-campaign.wizard-screens.filters.search'
                    )}
                />
                <div className="absolute inset-y-0 right-0 flex items-center">
                    <label htmlFor="column" className="sr-only">
                        {t(
                            'sections.campaigns.new-campaign.wizard-screens.filters.search'
                        )}
                    </label>
                </div>
            </div>
        </div>
    );
};

export default Search;
