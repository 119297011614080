import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { newCampaignActions, notificationsActions } from '../../../../../store';
import AsyncSelect from 'react-select/async';
import { getRandomHex, hexaToRgb } from '../../../../../utils/functions';
const colorsArr = hexaToRgb(getRandomHex(15));
let debounceLoadOptions;

const InputCitiesNeighborhoods = (props) => {
    const { id } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const location = useSelector((state) => state.newCampaign.location);

    const notExists = (search) => {
        return !location.cities.some((city) => city.search === search);
    };

    const onSelectPredicition = async (prediction) => {
        /*global google*/
        /*eslint no-undef: "error"*/
        const service = new google.maps.places.PlacesService(
            document.createElement('div')
        );
        const search = prediction.description;
        if (notExists(search)) {
            const request = {
                query: search,
            };
            const cities = [];
            service.textSearch(request, (results, status) => {
                if (status === 'OK') {
                    const city = {
                        value: search,
                        label: search,
                    };

                    cities.push(city);
                    const newLocation = Object.assign({}, location);
                    newLocation['selectedCities'] = cities;
                    newLocation['cities'] = cities;
                    dispatch(newCampaignActions.setLocation(newLocation));
                } else {
                    dispatch(
                        notificationsActions.setNotification({
                            type: 'error',
                            title: 'Error',
                            message: `Statuses Error: ${status}`,
                        })
                    );
                }
            });
        }
    };

    const loadOptions = (inputValue, callback) => {
        if (inputValue === '') {
            callback([]);
        } else {
            clearTimeout(debounceLoadOptions);
            debounceLoadOptions = setTimeout(() => {
                const service = new google.maps.places.AutocompleteService();
                service.getQueryPredictions(
                    { input: inputValue, types: ['(cities)'] },
                    (res) => {
                        if (res) {
                            const predictions = res.map((prediction) => ({
                                ...prediction,
                                label: prediction.description,
                                value: prediction.description,
                            }));
                            callback(predictions);
                        } else {
                            callback([]);
                        }
                    }
                );
            }, 300);
        }
    };

    return (
        <AsyncSelect
            cacheOptions
            loadOptions={loadOptions}
            onChange={onSelectPredicition}
            placeholder={t(
                'sections.campaigns.new-campaign.wizard-basics.location.cities-placeholder'
            )}
            noOptionsMessage={() =>
                t(
                    'sections.campaigns.new-campaign.wizard-basics.location.nearPlace-placeholder'
                )
            }
            value={location.cities}
            inputId={id}
        />
    );
};

export default InputCitiesNeighborhoods;
