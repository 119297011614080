import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../../Modal/Modal';
import ModalHeader from '../../../../../Modal/ModalHeader/ModalHeader';
import ModalBody from '../../../../../Modal/ModalBody/ModalBody';
import ModalFooter from '../../../../../Modal/ModalFooter/ModalFooter';
import CommonButton from '../../../../../Buttons/CommonButton';
import { useService } from '../../../../../../containers/UseService';
import {
    getTypeUsers,
    getUserRoles,
    roleUserToString,
    setLabelAndValue,
    typeUserToString,
} from '../../../../../../utils/functions';
import Basic from './Basic';
import Company from './Company';
import Ssps from './Ssps';
import CommercialContacts from './CommercialContacts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useDispatchNotification from '../../../../../../hooks/notifications/useDispatchNotification';

const UserModal = (params) => {
    const { isActive, toggler, onSubmit, user, edit } = params;
    const { t } = useTranslation();
    const { warningNotification } = useDispatchNotification();
    const service = useService();
    const initialStateUser = {
        id: '',
        username: '',
        margin: '0.00',
        email: '',
        company: null,
        companyName: '',
        showOohOption: true,
        password: '',
        confirmPassword: '',
        name: '',
        wantChangePassword: true,
        address: '',
        taxId: '',
        ssps: [],
        businessName: '',
        phoneNumber: '',
        roleSelected: null,
        hasCompany: false,
        isParentAccount: false,
        userSelected: null,
        typeUser: null,
        countrySelected: null,
        commercialContactName: '',
        commercialContactEmail: '',
        commercialContactPhoneNumber: '',
        cpm_mode: true,
    };
    const [userAux, setUserAux] = useState(initialStateUser);
    const translations =
        'sections.management.section.users.users.createUserModal.';
    const [canSubmit, setCanSubmit] = useState(true);
    const [isLoading, setIsLoading] = useState(user ? true : false);
    const [activeTab, setActiveTab] = useState('basic');
    const [companies, setCompanies] = useState([]);
    const [countries, setCountries] = useState([]);
    const [ssps, setSsps] = useState([]);
    const [commercialContacts, setCommercialContacts] = useState([]);
    const typesUsers = getTypeUsers().map((type) => ({
        value: type,
        label: t(translations + 'types.' + type),
    }));
    const roles = getUserRoles().map((role) => ({
        value: role,
        label: t(translations + 'roles.' + role),
    }));

    /**
     * Put all data from user in userAux
     */
    const mergeUserAuxWithUser = async (
        responseCompanies,
        responseCountries
    ) => {
        const findTypeUser = (type) =>
            type.value === typeUserToString(user.type);
        let merged = JSON.parse(JSON.stringify(initialStateUser));
        Object.keys(merged).forEach((key) => {
            merged[key] = user[key];
        });
        merged.margin = user.rtb_margin;
        merged.typeUser = typesUsers.find(findTypeUser);
        merged.phoneNumber = user.phone_number;
        merged.wantChangePassword = false;
        merged.showOohOption = user.show_ooh_option === 1;
        merged.isParentAccount =
            user.parent_id !== null && user.parent_id !== 0;
        if (merged.isParentAccount) {
            const parentUser = await service.getUserById(user.parent_id);
            parentUser.label = parentUser.username;
            parentUser.value = parentUser.id;
            merged.userSelected = parentUser;
        } else {
            merged.userSelected = null;
            const findRoleUser = (role) =>
                role.value === roleUserToString(user.roleNumber);
            merged.roleSelected = roles.find(findRoleUser);
        }
        merged.hasCompany = user.company_id !== null && user.company_id !== 0;
        if (merged.hasCompany) {
            merged.company = responseCompanies.find(
                (company) => company.value === user.company_id
            );
        } else {
            merged.company = null;
        }
        merged.companyName = user.company;
        merged.taxId = user.legal_id;
        merged.businessName = user.business_name;
        merged.countrySelected = responseCountries.find(
            (country) => country.id === user.account_country
        );

        merged.user = user.id;

        let userCommercialContacts = await service.getCommercialPersonsAudit(
            user.id
        );
        userCommercialContacts = setLabelAndValue(
            userCommercialContacts,
            'name',
            'id'
        );
        setCommercialContacts(userCommercialContacts);

        let userSsps = await service.getSspsByUser(user.id);
        userSsps = setLabelAndValue(userSsps, 'name', 'id');
        merged.ssps = userSsps;
        merged.originalUsername = merged.username;
        setUserAux(merged);
    };

    useEffect(() => {
        const fetchSsps = async () => {
            let ssps = await service.getSsps();
            ssps = setLabelAndValue(ssps, 'name', 'id');
            setSsps(ssps);
            return ssps;
        };

        const fetchCompanies = async () => {
            let companies = await service.getCompanies();
            companies = setLabelAndValue(companies, 'name', 'id');
            setCompanies(companies);
            return companies;
        };

        const fetchCountries = async () => {
            const countries = await service.getCountries();
            const countriesWithLabel = setLabelAndValue(
                countries,
                'name',
                'id'
            );
            setCountries(countriesWithLabel);
            return countriesWithLabel;
        };

        Promise.allSettled([fetchSsps(), fetchCompanies(), fetchCountries()])
            .then(([responseSsps, responseCompanies, responseCountries]) => {
                if (user) {
                    mergeUserAuxWithUser(
                        responseCompanies.value,
                        responseCountries.value
                    ).then(() => {
                        setIsLoading(false);
                    });
                }

                if (responseSsps.status === 'fulfilled') {
                    console.log('SSPs Loaded');
                } else {
                    console.error('Failed to load SSPs:');
                }
            })
            .catch((err) => {
                warningNotification(
                    t('common.notifications.error.title'),
                    t('common.notifications.error.errorData')
                );
            });
        if (!user) {
            setUserAux(initialStateUser);
        }
    }, []);

    const onClickSubmit = async () => {
        const responseUser = edit
            ? await service.editUser(userAux, user.id)
            : await service.createUser(userAux);
        onSubmit(responseUser);
    };

    const checkEmptyFields = (user, fields) => {
        let valid = true;
        fields.forEach((field) => {
            if (valid) {
                valid = user[field] !== '';
            }
        });
        return valid;
    };

    useEffect(() => {
        let parentAccount = userAux.isParentAccount
            ? userAux.userSelected !== null
            : userAux.roleSelected !== null;
        let validCompany = userAux.hasCompanyId
            ? userAux.company !== null && userAux.company !== undefined
            : true;
        const isValidEmail = (email) => email.includes('@');
        const isValidFields = checkEmptyFields(userAux, [
            'username',
            'margin',
            'companyName',
            'name',
            'address',
            'businessName',
            'taxId',
            'phoneNumber',
        ]);

        setCanSubmit(
            isValidEmail(userAux.email) &&
                isValidFields &&
                userAux.typeUser &&
                userAux.confirmPassword === userAux.password &&
                parentAccount &&
                validCompany &&
                userAux.address 
        );
    }, [userAux]);

    return (
        <Modal size="lg" active={isActive} toggler={toggler}>
            <ModalHeader toggler={toggler}>
                {edit
                    ? t(
                          'sections.management.section.users.users.createUserModal.editUser'
                      )
                    : t('sections.management.section.users.users.createButton')}
            </ModalHeader>
            <ModalBody>
                {isLoading ? (
                    <FontAwesomeIcon
                        icon={['fal', 'circle-notch']}
                        size="3x"
                        fixedWidth
                        className={
                            'p-2 flex mx-auto mt-10 animate-spin text-blue-400'
                        }
                    />
                ) : (
                    <>
                        <div className="px-10 w-11/12">
                            <div className=" flex flex-row  justify-start mb-4">
                                <button
                                    className={`${
                                        activeTab === 'basic'
                                            ? 'tabActive font-semibold'
                                            : ''
                                    } tabGeneral uppercase text-xs text-gray-600 py-2 px-4 focus:shadow rounded-t-xl`}
                                    onClick={() => setActiveTab('basic')}>
                                    {t(
                                        'sections.management.section.users.users.createUserModal.tabs.basic'
                                    )}
                                </button>
                                <button
                                    className={`${
                                        activeTab === 'ssps'
                                            ? 'tabActive font-semibold'
                                            : ''
                                    } tabGeneral uppercase text-xs text-gray-600 py-2 px-4 focus:shadow rounded-t-xl`}
                                    onClick={() => setActiveTab('ssps')}>
                                    {t(
                                        'sections.management.section.users.users.createUserModal.tabs.ssps'
                                    )}
                                </button>

                                <button
                                    className={`${
                                        activeTab === 'company'
                                            ? 'tabActive font-semibold'
                                            : ''
                                    } tabGeneral uppercase text-xs text-gray-600 py-2 px-4 focus:shadow rounded-t-xl`}
                                    onClick={() => setActiveTab('company')}>
                                    {t(
                                        'sections.management.section.users.users.createUserModal.tabs.company'
                                    )}
                                </button>

                                {edit && (
                                    <button
                                        className={`${
                                            activeTab === 'commercial-contacts'
                                                ? 'tabActive font-semibold'
                                                : ''
                                        } tabGeneral uppercase text-xs text-gray-600 py-2 px-4 focus:shadow rounded-t-xl`}
                                        onClick={() =>
                                            setActiveTab('commercial-contacts')
                                        }>
                                        {t(
                                            'sections.management.section.users.users.createUserModal.tabs.commercial-contacts'
                                        )}
                                    </button>
                                )}
                            </div>
                        </div>

                        <div className="pl-10 pr-10 flex flex-col">
                            <div
                                className={`${
                                    activeTab === 'basic' ? 'block' : 'hidden'
                                }  flex justify-center`}>
                                <Basic
                                    roles={roles}
                                    edit={edit}
                                    companies={companies}
                                    translations={translations}
                                    user={userAux}
                                    setUser={setUserAux}
                                    typesUsers={typesUsers}
                                />
                            </div>

                            <div
                                className={
                                    activeTab === 'ssps'
                                        ? 'block flex justify-center'
                                        : 'hidden'
                                }>
                                <Ssps
                                    user={userAux}
                                    setUser={setUserAux}
                                    ssps={ssps}
                                />
                            </div>

                            <div
                                className={`${
                                    activeTab === 'company' ? 'block' : 'hidden'
                                }  flex justify-center `}>
                                <Company
                                    countries={countries}
                                    translations={translations}
                                    user={userAux}
                                    setUser={setUserAux}
                                />
                            </div>
                            {edit && (
                                <div
                                    className={
                                        activeTab === 'commercial-contacts'
                                            ? 'block'
                                            : 'hidden'
                                    }>
                                    <CommercialContacts
                                        setCommercialContacts={
                                            setCommercialContacts
                                        }
                                        commercialContacts={commercialContacts}
                                        translations={translations}
                                        user={userAux}
                                        setUser={
                                            setUserAux
                                        }></CommercialContacts>
                                </div>
                            )}
                        </div>
                    </>
                )}
            </ModalBody>
            {activeTab !== 'commercial-contacts' && (
                <ModalFooter>
                    <div className={'flex justify-end'}>
                        <CommonButton
                            disabled={!canSubmit}
                            label={t('common.words.save')}
                            styleType={'primary'}
                            onClick={onClickSubmit}
                        />
                    </div>
                </ModalFooter>
            )}
        </Modal>
    );
};

export default UserModal;
