import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useService } from '../../containers/UseService';
import ContinueCampaignModal from './ContinueCampaignModal';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { fromStatusToLinkPath } from '../../utils/functions';

const leftMenuVariants = {
    open: { opacity: 1, x: 0 },
    closed: { opacity: 0, x: '-100%' },
};

function LeftMenu() {
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const [showModalContinueCampaign, setShowModalContinueCampaign] =
        useState(false);
    const toggleModalContinueCampaign = () =>
        setShowModalContinueCampaign(!showModalContinueCampaign);
    const service = useService();
    const campaignsSelector = useSelector((state) => state.campaigns);
    const statuses = campaignsSelector.statuses
        ? campaignsSelector.statuses
        : [];
    const [isCampaignOpen, setIsCampaignOpen] = useState(false);
    const [newStatus, setNewStatus] = useState(statuses);
    const statusesInLocalStorage = localStorage.getItem('statuses')
        ? JSON.parse(localStorage.getItem('statuses'))
        : [];
    const isCampaignsLoading = false; // Temporário enquanto o uso real está em revisão
    // const wallet = useSelector((state) => state.wallet.budgets);

    useEffect(async () => {
        try {
            const campaigns = await service.getCampaignsPerStatus();
            setNewStatus(campaigns);
        } catch (error) {
            console.log(error);
        }
    }, []);

    const toggleMenu = () => setIsCampaignOpen(!isCampaignOpen);

    const keepMenuOpen = () => setIsCampaignOpen(true);

    const onClickNewCampaign = () => {
        setIsCampaignOpen(!isCampaignOpen);
        if (service.hasDraftCampaignInLocalStorage()) {
            setShowModalContinueCampaign(true);
        } else {
            goToNewCampaign();
        }
    };

    const goToNewCampaign = () => {
        const routeToNewCampaign = '/campaign/new';
        const routeToEditCampaign = '/campaign/edit';

        if (location.pathname.includes(routeToEditCampaign)) {
            window.location.href = routeToNewCampaign;
        } else {
            if (location.pathname === routeToNewCampaign) {
                history.go(); //refresh
            } else {
                history.push('/campaign/new');
            }
        }
    };

    return (
        <>
            <ul className="list-none w-100 p-4 relative overflow-hidden">
                <li className="linkLeft my-4 h-10">
                    <NavLink
                        to="/dashboard"
                        className="hidden group-hover:block text-sm">
                        {t('common.menu.dashboard')}
                        <br />
                        <span className="font-thin text-gray-400 text-xs">
                            {newStatus['ACTIVE']}{' '}
                            {t('common.menu.activeCampaigns')}
                        </span>
                    </NavLink>
                    <FontAwesomeIcon
                        icon={['fal', 'analytics']}
                        className="ml-2"
                    />
                </li>
                <li className={`linkLeft my-4 h-10`}>
                    <NavLink
                        to="/management"
                        className="hidden group-hover:block text-sm">
                        {t('common.menu.management')}
                    </NavLink>
                    <FontAwesomeIcon icon={['fal', 'cog']} className="ml-2" />
                </li>
                {isCampaignsLoading && (
                    <li className="flex justify-between content-center font-light text-sm my-2 text-gray-600 cursor-default">
                        <span className="hidden group-hover:block text-sm">
                            {t('common.words.loading')}...
                        </span>
                        <FontAwesomeIcon
                            icon={['fas', 'circle-notch']}
                            className="text-blue-400 animate-spin ml-2"
                        />
                    </li>
                )}
                {!isCampaignsLoading && (
                    <div>
                        <li className="linkLeft my-4">
                            <button
                                className="hidden group-hover:block text-sm"
                                onClick={toggleMenu}>
                                {t('common.menu.campaigns')}{' '}
                                <FontAwesomeIcon
                                    icon={['fal', 'chevron-down']}
                                    className={`transition-transform duration-150 transform text-xs ${
                                        isCampaignOpen
                                            ? 'rotate-0'
                                            : 'rotate-90'
                                    }`}
                                />
                            </button>
                            <FontAwesomeIcon
                                icon={['fal', 'bullseye']}
                                className="ml-2"
                            />
                        </li>

                        {isCampaignOpen && (
                            <>
                                <motion.ul
                                    className="list-none w-100 opacity-0 pl-4 py-2 group overflow-hidden hidden group-hover:block"
                                    animate={isCampaignOpen ? 'open' : 'closed'}
                                    variants={leftMenuVariants}>
                                    <li
                                        className="link text-sm"
                                        onClick={onClickNewCampaign}>
                                        {t('common.menu.newCampaign')}
                                    </li>
                                    {Object.keys(statusesInLocalStorage).map(
                                        (status, index) => (
                                            <li
                                                className="linkLeft"
                                                key={index}
                                                onClick={keepMenuOpen}>
                                                <NavLink
                                                    to={`/campaigns/status/${fromStatusToLinkPath(
                                                        status
                                                    )}`}
                                                    className={
                                                        location.pathname ===
                                                        `/campaigns/status/${fromStatusToLinkPath(
                                                            status
                                                        )}`
                                                            ? 'font-bold'
                                                            : ''
                                                    }>
                                                    {t(
                                                        `common.campaign.detail.${status}`
                                                    )}
                                                </NavLink>
                                                {statusesInLocalStorage[status]}
                                            </li>
                                        )
                                    )}
                                </motion.ul>
                            </>
                        )}
                    </div>
                )}
            </ul>
            <ContinueCampaignModal
                goToNewCampaign={goToNewCampaign}
                isActive={showModalContinueCampaign}
                toggler={toggleModalContinueCampaign}
            />
        </>
    );
}

export default LeftMenu;
