import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    formatScreenLocation,
    formatNumber,
} from '../../../../utils/functions';
import LinkButton from '../../../Buttons/LinkButton';
import { newCampaignActions } from '../../../../store';
import DownloadScreensToCSV from './DownloadScreensToCSV';

const Screens = ({ userMargin }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const screensContainerRef = useRef();
    const newCampaign = useSelector((state) => state.newCampaign);
    const isResizeMapOn = newCampaign.isResizeMapOn;
    const selectedScreens = newCampaign.selectedScreens;
    const maxScreens = 4;
    const selectedCurrency = useSelector(
        (state) => state.newCampaign.selectedCurrency
    );
    const [screens, setScreens] = useState(
        selectedScreens.slice(0, maxScreens)
    );

    const onShowAllScreens = () => {
        setScreens(selectedScreens);
    };

    let screenCpm = [];

    selectedScreens.forEach((screen) => {
        screenCpm.push(formatNumber(screen.cpm, 'currency'));
    });

    const showInMap = (screen) => {
        dispatch(
            newCampaignActions.setCurrentPositionMap({
                lat: screen.geo.lat,
                lng: screen.geo.lon,
                zoom: 19,
            })
        );
    };

    return (
        <div className="block py-4">
            <div className="flex mb-2">
                <FontAwesomeIcon
                    icon={['fal', 'sign']}
                    fixedWidth
                    size="lg"
                    className="m-1 text-blue-300"
                />
                <span className="text-xl font-light">
                    {t(
                        'sections.campaigns.new-campaign.wizard-review.blocks.screens.title'
                    )}
                </span>
            </div>
            <div className="flex flex-col divide-y divide-dotted">
                <div className="p-2 font-light text-gray-400">
                    {t(
                        'sections.campaigns.new-campaign.wizard-review.blocks.screens.screens-selected'
                    )}
                    <br />
                    <span className="font-normal text-gray-700 text-lg">
                        {selectedScreens.length}
                    </span>
                </div>
                <div
                    className={`p-2 font-light text-gray-400 ${
                        screens.length === 0 ? 'hidden' : ''
                    }`}>
                    <div className="flex justify-between mb-2">
                        <div>
                            {t(
                                'sections.campaigns.new-campaign.wizard-review.blocks.screens.screen-list'
                            )}
                        </div>
                        {/* Ver que librería usar para transformar el resumen de esta página en un PDF. */}
                        <div>
                            <DownloadScreensToCSV userMargin={userMargin} />
                        </div>
                    </div>
                    <ol
                        ref={screensContainerRef}
                        className="list-none mt-2 divide-y">
                        {screens.map((screen, index) => (
                            <li
                                key={index}
                                className={`${
                                    isResizeMapOn ? 'flex-col' : 'md:flex-row'
                                } flex md:justify-start flex-col py-2`}>
                                {/* Cuando tengamos la imagen de la screen la mostramos
                                <div className={`${isResizeMapOn ? 'w-full' : 'md:w-2/6'} pr-4`}>
                                    <img className="rounded object-cover w-full shadow-lg border-2 border-white" src={Photo01} alt="screen" />
                                </div> */}
                                <div className="md:w-4/6 mt-2 md:mt-0 text-gray-900">
                                    <ul>
                                        <li>
                                            <span className="text-xl text-gray-300">
                                                #{index + 1}
                                            </span>{' '}
                                            <span className="text-xl font-normal">
                                                {screen.name}
                                            </span>
                                        </li>
                                        <li>
                                            <span className="font-semibold">
                                                {t(
                                                    'sections.campaigns.new-campaign.wizard-review.blocks.screens.screen-details.location'
                                                )}
                                                :{' '}
                                            </span>{' '}
                                            {formatScreenLocation(screen)}
                                        </li>
                                        <li>
                                            <span className="font-semibold">
                                                {t(
                                                    'sections.campaigns.new-campaign.wizard-review.blocks.screens.screen-details.coordinates'
                                                )}
                                                :{' '}
                                            </span>{' '}
                                            <LinkButton
                                                onClick={() =>
                                                    showInMap(screen)
                                                }>
                                                {' '}
                                                {screen.geoLat}, {screen.geoLon}{' '}
                                            </LinkButton>
                                        </li>
                                        <li>
                                            <span className="font-semibold">
                                                {t(
                                                    'sections.campaigns.new-campaign.wizard-review.blocks.screens.screen-details.size'
                                                )}
                                                :{' '}
                                            </span>{' '}
                                            {`${screen.w}x${screen.h}`}
                                        </li>
                                        <li>
                                            <span className="font-semibold">
                                                {t(
                                                    'sections.campaigns.new-campaign.wizard-review.blocks.screens.screen-details.CPM'
                                                )}
                                                :{' '}
                                            </span>{' '}
                                            {/* {isNaN(screen.ecpm)
                                                ? screen.ecpm
                                                : selectedCurrency.symbol +
                                                  ' ' +
                                                  formatNumber(
                                                      screen.ecpm,
                                                      'currency'
                                                  )} */}
                                        </li>
                                        <li>
                                            <span className="font-semibold">
                                                {t(
                                                    'sections.campaigns.new-campaign.wizard-review.blocks.screens.screen-details.id'
                                                )}
                                                :{' '}
                                            </span>{' '}
                                            {screen.id}
                                        </li>
                                        {/* Cuando tengamos los siguientes datos los mostramos
                                        <li><span className="font-semibold">{t("sections.campaigns.new-campaign.wizard-review.blocks.screens.screen-details.restrictions")}:</span> Religion, Politics, Alcohol</li>
                                        <li><span className="font-semibold">{t("sections.campaigns.new-campaign.wizard-review.blocks.screens.screen-details.slot-duration")} Duration:</span> 15"</li>
                                        <li><span className="font-semibold">{t("sections.campaigns.new-campaign.wizard-review.blocks.screens.screen-details.specs")}:</span> Images: 1mb / Videos: 5mb.</li> */}
                                    </ul>
                                </div>
                            </li>
                        ))}
                    </ol>
                </div>
                <span
                    className={
                        selectedScreens.length > maxScreens &&
                        screens.length <= maxScreens
                            ? ''
                            : 'hidden'
                    }>
                    <LinkButton onClick={onShowAllScreens}>
                        {t(
                            'sections.campaigns.new-campaign.wizard-review.blocks.screens.view-all'
                        )}
                    </LinkButton>
                </span>
            </div>
        </div>
    );
};

export default Screens;
